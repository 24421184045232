<template>
  <nav class="pt-4 w-100">
    <div
      :class="
        $route.name === 'SwitchApplication' ? 'container-fluid' : 'container'
      "
      class="d-flex justify-content-between align-items-center"
    >
      <div class="logo">
        <img
          class="width-175"
          v-if="getAssets && getAssets.horizontalTeamLogo"
          :src="getAssets.horizontalTeamLogo"
          alt="Team-Logo"
        />
      </div>
      <button
        type="button"
        :class="openSideBar ? 'active' : ''"
        @click="showSidebar"
        class="burger"
        id="burger"
      >
        <span class="burger-line"></span>
        <span class="burger-line"></span>
        <span class="burger-line"></span>
        <span class="burger-line"></span>
      </button>
      <div class="dropdown" v-if="getIsAuthenticated">
        <button
          type="button"
          id="my-account"
          class="dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          My Account
          <down-arrow />
        </button>
        <ul class="dropdown-menu" aria-labelledby="my-account">
          <li>
            <router-link to="/switch-application" class="dropdown-item"
              >Switch Application</router-link
            >
          </li>
          <li>
            <router-link to="/change-email" class="dropdown-item"
              >Change Email</router-link
            >
          </li>
          <li>
            <router-link to="/change-password" class="dropdown-item"
              >Change Password</router-link
            >
          </li>
          <li>
            <a @click="logout" class="cursor-pointer dropdown-item">Sign out</a>
          </li>
        </ul>
      </div>
      <div v-else>
        <router-link to="/sign-in" class="text-secondary" role="button">
          Sign-In
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "./../event-bus";
import DownArrow from "./DownArrow.vue";

export default {
  name: "Navbar",
  components: { DownArrow },
  data() {
    return {
      openSideBar: false,
    };
  },
  computed: {
    ...mapGetters([
      "getProspectId",
      "getOrgId",
      "getCompanyName",
      "getIsAuthenticated",
      "getAssets",
      "getActiveSection",
      "getSocialLogin",
      "getFacebookData",
      "getFacebookScope",
    ]),
  },
  created() {
    eventBus.$on("open-sidebar", (data) => {
      this.openSideBar = data;
    });
  },
  methods: {
    ...mapActions([
      "resetUserStore",
      "resetQuestionnaireStore",
      "resetAddressStore",
      "resetMcStore",
    ]),

    showSidebar() {
      this.openSideBar = !this.openSideBar;
      eventBus.$emit("mobile-view", this.openSideBar);
    },

    async logout() {
      // Google signout
      if (this.getSocialLogin === "Google") await this.$gAuth.signOut();

      // Facebook signout
      if (this.getSocialLogin === "Facebook" && this.getFacebookScope.logout) {
        await this.getFacebookScope.logout();
      }

      this.resetUserStore();
      this.resetQuestionnaireStore();
      this.resetAddressStore();
      this.resetMcStore();

      this.$router.push("/log-out");
    },
  },
};
</script>

<style>
.width-175 {
  width: 175px;
  max-width: 175px;
}
</style>